export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result;

    const later = function () {
        // According to the last trigger interval
        const last = new Date().getTime() - timestamp;

        // The last time the wrapped function was called, the interval is last less than the set time interval wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            // If it is set to immediate===true, since the start boundary has already been called, there is no need to call it here.
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) {
                    context = args = null;
                }
            }
        }
    };

    return function (...args) {
        context = this;
        timestamp = new Date().getTime();
        const callNow = immediate && !timeout;
        // If the delay does not exist, reset the delay
        if (!timeout) {
            timeout = setTimeout(later, wait);
        }
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
}

export const colorTracing = params => {
    switch (params) {
        case 'Se reestablece el Evento':
            return '#0bef09';
        default:
            return '#f30d0d';
    }
};
