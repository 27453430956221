<template>
  <div :class="className" :style="{height:height,width:width}"></div>
</template>

<script>
import * as echarts from 'echarts';

require('echarts/theme/macarons');
import {debounce, colorTracing} from '../../../utils/index';

export default {
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '1980px',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    chartData: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    chart: null,
    sidebarElm: null,
  }),
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.initChart();

    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize();
        }
      }, 100);
      window.addEventListener('resize', this.__resizeHandler);
    }

    // Monitor the sidebar changes
    this.sidebarElm = document.getElementsByClassName('sidebar-container')[0];
    this.sidebarElm && this.sidebarElm.addEventListener('transitionend', this.sidebarResizeHandler);
  },
  beforeUnmount() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler);
    }

    this.sidebarElm && this.sidebarElm.removeEventListener('transitionend', this.sidebarResizeHandler);

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler();
      }
    },

    setOptions: function ({dayData, hourData, valueData} = {}) {

      this.chart.setOption({
        tooltip: {
          position: 'right',
          formatter: function (params) {
            return `${params.value[2]} <br> ${params.value[3]} <br> ${params.value[4]} <br> ${params.value[5]} <br> ${params.value[6]}`;
          },
          axisPointer: {
            type: 'cross',
          },
          padding: ['50%', '50%'],
          textStyle: {
            width: '30%',
          },
          extraCssText: 'white-space: normal;',
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: dayData,
          axisLine: {
            show: true
          },
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#999',
              type: 'dashed'
            }
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: hourData,
          axisLine: {
            show: true
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: 'Analysis',
            itemStyle: {
              normal: {
                color: function (params) {
                  let s = colorTracing(params.data[7]);
                  return s;
                },
                lineStyle: {
                  color: '#5cc9f5',
                  width: 2,
                },
              }
            },
            smooth: true,
            type: 'scatter',
            data: valueData,
            animationDuration: 2800,
            animationEasing: 'cubicInOut',
          }
        ]
      });
    },

    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    },
  },
};
</script>
