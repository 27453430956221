<template>
  <div class="container-fluid col-10">
    <section class="col-sm-12" style="min-height: calc(100vh - 84px);">

      <b-card aria-hidden="true" v-if="load">
        <b-card no-body>
          <b-card title=" " class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </b-card>
          <b-card-text class="placeholder-glow">
            <span class="placeholder col-12"></span>
            <span class="placeholder col-12"></span>
            <span class="placeholder col-12"></span>
            <span class="placeholder col-12"></span>
          </b-card-text>
        </b-card>
      </b-card>

      <div v-else>
        <NoData v-if="(!hours.length >= 1 || !comments.length >= 1)"/>

        <div class="row" style="background:#fff;padding:16px 16px 0;margin-bottom:32px;" v-else>
          <VueMultiselect
              style="width: 98%"
              v-model="selectedMetric"
              placeholder="Seleccione una métrica"
              :options="metrics"
              :allow-empty="false"
              @select="onChange"
          />

          <LineChart
              :chart-data="lineChartData"
              :height="lineChartHeight"
          />

        </div>
      </div>

    </section>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import LineChart from './LineChart.vue';
import NoData from '@/views/pages/Error/NoData.vue';
import {GROUP_CHART_APP} from "@/graphql/queries/groups/chartApp";
import {notification} from "@/helpers/notifications";
import {format} from "date-fns";
import {createdAtFormat} from "@/helpers/helper";

export default {
  name: 'Chart',
  data: () => ({
    load: true,
    selectedMetric: null,
    commentsOfWeek: []
  }),
  components: {
    VueMultiselect,
    LineChart,
    NoData
  },
  mounted() {
    this.getGraphicComments();
  },
  methods: {
    async getGraphicComments() {
      try {
        this.load = true

        const {data} = await this.$apollo.query({
          query: GROUP_CHART_APP,
          fetchPolicy: "network-only",
          variables: {
            id: this.$route.params.id,
            token: this.$route.params.token,
          }
        });

        this.commentsOfWeek = data.chartApp;
        this.load = false;
      } catch (errors) {
        this.load = false
        notification('error', errors.message);
        return [];
      }
    },

    onChange(selectedOption) {
      this.selectedMetric = selectedOption;

      this.chartHeight();
    },

    chartHeight() {
      const numberComments = this.comments.length;
      let height = '1980px';

      if (numberComments !== null && numberComments !== 'undefined') {
        height = `${(numberComments * 67)}px`;
      }

      return height;
    }
  },
  watch: {
    metrics: function (value) {
      this.selectedMetric = value[0];
    }
  },
  computed: {
    comments() {
      if (this.selectedMetric === 'undefined' || this.selectedMetric === null) {
        return Object.values(this.commentsOfWeek);
      }
      return (Object.values(this.commentsOfWeek) || [])
          .filter(comment => comment.comment.metric === this.selectedMetric);
    },

    hours() {
      const data = [];
      const commentsOfWeek = this.comments;

      (Object.values(commentsOfWeek) || []).forEach(comment => {
        if (comment.created_at !== null || comment.created_at !== 'undefined') {
          const minute = format(new Date(comment.created_at), "h:mm:ss aaa");

          data.push(minute);
        }
      });

      return data;
    },

    lineChartData() {
      let data = [];
      let hours = this.hours;
      const commentsOfWeek = this.comments;

      (Object.values(commentsOfWeek) || []).forEach(comment => {
        if (comment.created_at !== null || comment.created_at !== 'undefined') {

          const date = comment.created_at;
          const fullDay = createdAtFormat(date);
          let day = format(new Date(date), 'd');
          const minute = format(new Date(date), "h:mm:ss aaa");

          day = (day === 0 || day === 7) ? 6 : (day - 1);

          data.push([
            day,
            hours.indexOf(minute),
            `Host: ${comment.comment.host}`,
            `Métrica: ${comment.comment.monitor}`,
            `Valor: ${comment.comment.event_detail}`,
            `Día: ${fullDay}`,
            `Hora: ${minute}`,
            comment.comment.tracing
          ]);
        }
      });

      return {
        dayData: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        hourData: hours,
        valueData: data,
      };
    },

    metrics() {
      let data = [];

      (Object.values(this.commentsOfWeek) || []).forEach(comment => {
        const item = comment.comment.metric;
        if (data.indexOf(item) === -1) {
          data.push(item);
        }
      });

      return data;
    },

    lineChartHeight() {
      return this.chartHeight();
    }
  }
}
</script>
